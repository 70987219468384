import React, { useState, useEffect } from 'react';
import { 
  Alert, 
  AlertTitle, 
  Paper, 
  Typography, 
  Box,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorFallback = ({ error, resetErrorBoundary, isRetrying, isOffline }) => {
  const isClerkError = error?.toString().includes('ClerkJS');

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          maxWidth: 500,
          width: '100%',
          p: 3
        }}
      >
        <Alert 
          severity={isOffline ? "warning" : "error"}
          icon={<ErrorOutlineIcon />}
          sx={{ mb: 2 }}
        >
          <AlertTitle>
            {isOffline 
              ? "No Internet Connection"
              : isClerkError 
                ? "Authentication Error" 
                : "Something went wrong"}
          </AlertTitle>
          
          {isOffline ? (
            <Typography variant="body1" sx={{ mb: 2 }}>
              You are currently offline. Please check your internet connection.
            </Typography>
          ) : isClerkError ? (
            <Typography variant="body1" sx={{ mb: 2 }}>
              {isRetrying ? 
                'Attempting to restore your session...' : 
                `We're having trouble with the authentication service.`}
            </Typography>
          ) : (
            'An unexpected error occurred. Please try again later.'
          )}
        </Alert>
      </Paper>
    </Box>
  );
};

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  
  useEffect(() => {
    const handleError = (event) => {
      const error = event.error || event.reason || event;
      console.error('Caught error:', error);

      if (!navigator.onLine) {
        setIsOffline(true);
      }
      
      if (!isOffline && error.toString().includes('ClerkJS')) {
        console.warn('Suppressing Clerk session errors while offline.');
        return;
      }

      setError(error);
    };

    const updateOnlineStatus = () => {
      setIsOffline(!navigator.onLine);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [isOffline]);

  if (error || isOffline) {
    return <ErrorFallback error={error} isOffline={isOffline} />;
  }

  return children;
};

export default ErrorBoundary;