import { Cancel, Language, Menu, Search } from '@mui/icons-material'
import { AppBar, Avatar, Box, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { sideBarWidth } from '../utils'
import CustomUserButton from './CustomUserbutton'
import { useUser } from '@clerk/clerk-react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSearch } from '../slices/notesSlice'
import { Link } from 'react-router-dom'

const debouncDelay = 500;

const Header = ({
  drawerOpen,
  setDrawerOpen,
  setMobileOpen
}) => {
  const { user } = useUser();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { hasActiveSubscription } = useSelector((state) => state.stripeSubscription);

  const enterpriseUser = user?.organizationMemberships[0]?.organization?.id
  const createdAt = new Date(user.createdAt);
  const currentDate = new Date();
  const diffInMs = currentDate - createdAt;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  const isMoreThan14Days = diffInDays >= 14;

  const isAdmin = user?.publicMetadata?.isSuperAdmin || '';

  useEffect(()=>{
    const handler = setTimeout(()=>{
      dispatch(updateSearch(search));
    },debouncDelay)
    return ()=> clearTimeout(handler)
  },[search])
  return (
    <AppBar variant='outlined' sx={{
      backgroundColor: 'white',
      border: 'none',
      width: { md: `calc(100% - ${drawerOpen ? sideBarWidth : 0}px)`, sm: '100%', xs: '100%' },
      transition: '0.2s ease-in-out'
    }} position='fixed'>
      <Toolbar>
        <Box width='100%' component='div' display='flex' justifyContent='space-between' alignItems='center' padding={'15px'}>
          <Box component='div'  display='flex' columnGap='25px' alignItems='center'>
            <Menu onClick={() => { setDrawerOpen((open) => !open) }} fontSize='large'
              sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
            <Menu onClick={() => { setMobileOpen((open) => !open) }} fontSize='large'
              sx={{ color: '#1D2939', cursor: 'pointer', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} />
              <TextField
                size='small'
                sx={{
                  backgroundColor: '#F5F6FA',
                  width: '400px'
                }}
                variant='outlined' 
                placeholder= {isAdmin ? "Search by CC, Author or Author Email" : "Search by chief complaint..."}
                value={search}
                onChange={(e)=> setSearch(e.target.value)}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {
                          search && search.length > 0 && 
                          (
                            <IconButton onClick={()=>setSearch('')}>
                          <Cancel />
                        </IconButton>
                          ) 
                        }
                      </InputAdornment>
                    )
                  }
                }}
              />
          </Box>
          <Box component='div' display='flex' columnGap='25px' alignItems='center'>
          {!user.publicMetadata.isSuperAdmin && (
          <Typography
            color="#2E6FF3"
            fontSize="16px"
            fontWeight={700}
            component={enterpriseUser ? "span" : Link}
            to= "/subscription"
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              padding: '5px 10px',
              borderRadius: '5px',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#EAF4FF';
              e.target.style.color = '#0056b3';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#2E6FF3';
            }}
          >
            { enterpriseUser ? "Pro" : !isMoreThan14Days ? 'Premium' : hasActiveSubscription ? 'Premium' : 'Basic'}
          </Typography>
          )}
            <Box component='div' display="flex" alignItems="center" gap={2}>
              <CustomUserButton />
              <Typography color='#404040' fontSize="14px" fontWeight={700} marginTop="5px">
                {user.fullName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header