import { useState, useCallback } from 'react';
import { useAuth} from '@clerk/clerk-react';
import requests from '../api/agnet';
import { enqueueSnackbar } from 'notistack';

const useApiRequest = ({handleResponse, handleError, successMessage = "Completed SuccessFully", showSuccessSnackbar = true, showErrorSnackBar = true}) => {
  const {getToken} = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const startLoading = () => setLoading(true);
  const endLoading = () => setLoading(false);

  const apiRequest = useCallback(
    async (url, requestType = 'get', body) => {
      startLoading();
      try {
        const response = await requests[requestType](
          url,
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          },
          body
        );
        setData(response);

        // Safely call handleResponse
        try {
          if (handleResponse) {
            await handleResponse(response);
          }
        } catch (responseError) {
          console.error('Error in handleResponse:', responseError);
        }

        // Show success message only for non-GET requests
        if (requestType !== 'get' && showSuccessSnackbar) {
          enqueueSnackbar({
            variant: 'success',
            message: successMessage,
          });
        }
      } catch (err) {
        setError(err);
        if (handleError) {
          handleError(err);
        }
        if (showErrorSnackBar) {
          enqueueSnackbar({
            variant: 'error',
            message: 'Something Went Wrong!',
          });
        }
      } finally {
        endLoading();
      }
    },
    [getToken, handleResponse, handleError, showSuccessSnackbar, showErrorSnackBar, successMessage]
  );

  const resetResponse = () => {
    setData(null);
    setError(null);
  };

  return {
    loading,
    data,
    error,
    apiRequest,
    resetResponse,
  };
};

export default useApiRequest;
