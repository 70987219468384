import { Description, Edit, Notes, Subscriptions, Tune } from "@mui/icons-material";
import moment from "moment/moment";
import Mips from "../components/clinicalDecesionTols/Mips";
import Heart from "../components/clinicalDecesionTols/Heart";
import Pecarn from "../components/clinicalDecesionTols/Pecarn";
import VTE from '../components/clinicalDecesionTols/VTE';
import CTHead from "../components/clinicalDecesionTols/CTHead";

const enableStripe = process.env.REACT_APP_ENABLE_STRIPE;

export const sideBarWidth = 240;

const allowedEmails = [
  // "kamran@drh.ai",
  "ghulam.hur@megasight.ai",
  "hur@drh.ai",
  "moazmumtaz998@gmail.com"
].map(email => email.toLowerCase());

export const sideBarList = (isSuperAdmin, userEmail) => [
  {
    text: 'Notes',
    icon: <Description />,
    path: '/all-notes'
  },
  {
    text: 'Note Templates',
    icon: <Edit />,
    path: '/prompts'
  },
  (allowedEmails.includes(userEmail) && isSuperAdmin) && {
    text: 'Prompt Customization',
    icon: <Tune />,
    path: '/prompt/customization'
  }
].filter(Boolean)

export const sections = [
  {
    title: "Notes Sections",
    addnewOption: true,
    description: 'Select the sections you want have on your notes',
    options: ["HPI", "External Records Reviewed", "Allergies", "Medications", "Review of Systems", "Physical Exam", "Plan", "Differential Diagnosis"]
  },
  {
    title: "Care Update Sections",
    addnewOption: false,
    description: 'Select the sections you want have on your reassessment notes',
    options: ["Disposition", "Summary of encounter", "Assessment", "Medications", "MDM Risks"]
  },
  {
    title: "Disposition Sections",
    addnewOption: false,
    description: 'Select the sections you want have on your disposition notes',
    options: ["Disposition", "Summary of encounter", "Assessment", "Medications", "Differential Diagnosis", "MDM Risks"]
  },
];


export const updateSection = (index, option) => {
  sections[index].options.push(option);
}

export const allNotestableHeadData = [
  {
    label : 'Patient Identifier',
  },
  {
    label : 'Location',
  },
  {
    label: 'Created At'
  },
  {
    label : 'Status'
  },
  {
    label : 'Actions'
  },
  {
    label : 'Author',
  },
  {
    label : 'Author email',
  },
];

const adminAdditionalColumns = [
  {
    label : 'Author',
  },
  {
    label : 'Author email',
  },
  {
    label: 'Actions'
  }
]

export const getNotestableHeaders = (isSuperAdmin = false) => ([
  {
    label : 'Patient Identifier',
  },
  {
    label : 'Location',
  },
  {
    label: 'Chief Complaint'
  },
  {
    label: 'Created At'
  },
  // {
  //   label : 'Status'
  // },
  ...(isSuperAdmin ? adminAdditionalColumns : [{ label: 'Actions'}]),

])


export const dummyNotes = [
  {
    id: 1,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
  {
    id: 2,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
  {
    id: 3,
    patientIdentifier: 'AB | M | 20 Years',
    chiefComplaint: 'Dog Bite',
    location: 'Room 44',
    status: 'Completed',
    createdAt: '07/22/24 | 14:22',
  },
];

export const locations = ['Room 1', 'Room 2', 'Room 3'];


export const ageUnits = [
  {label: 'Years', value: 'YEARS'},
  {label: 'Months', value: 'MONTHS'},
  {label: 'Weeks', value: 'WEEKS'},
  {label: 'Days', value: 'DAYS'},
];

export const locationType = [
  {label: 'Room', value: 'ROOM'},
  {label: 'Hall', value: 'HALL'},
  {label: 'Chair', value: 'CHAIR'},
  {label: 'Other', value: 'OTHER'}
]

export const numberLocation = ['CHAIR', 'ROOM'];

export const statuses = ['Completed', 'Dispositioned'];

export const noteTypes = [
  'Notes',
  'Drafts',
  'Archived'
]

export const noteOptions = ['Care Update', 'EKG', 'Disposition'];

export const notevalues = {
  'Care Update': { label: 'Care Update',noteType: 'care-up', startStatus: 'CARE_UP_UPLOAD_STARTED', endStatus: 'CARE_UP_UPLOADED'},
  'EKG': { label: 'EKG', noteType: 'ekg', startStatus: 'EKG_UPLOAD_STARTED', endStatus: 'EKG_UPLOADED'},
  'Disposition': { label: 'Disposition', noteType: 'follow-up', startStatus: 'FOLLOW_UP_UPLOAD_STARTED', endStatus: 'FOLLOW_UP_UPLOADED'}
}


export const genders = [
  {label: 'Male', value: 'MALE'}, 
  {label: 'Female', value: 'FEMALE'}
];

export const getFormatedDateAndTime = (dateTime) =>{
  const localTime = moment(dateTime).local();
  const date = localTime.format('MM/DD/YYYY');
  const time = localTime.format('HH:mm');
  return `${time} | ${date}`;
}
// formating content time
export const formatContentWithLocalTime = (content) => {
  if (!content || typeof content !== 'string') {
    console.error('Invalid content provided. Expected a non-empty string.');
    return content;
  }

  const timeRegex = /(?:Time:\s*)?(\d{2}\/\d{2}\/\d{2})\s*\|\s*(\d{2}:\d{2})/g;

  try {
    const updatedContent = content.replace(timeRegex, (fullMatch, datePart, timePart) => {
      const dateTimeString = `${datePart} ${timePart}`;
      const localTime = moment.utc(dateTimeString, 'MM/DD/YY HH:mm');

      if (!localTime.isValid()) {
        console.warn(`Invalid date/time encountered: ${dateTimeString}`);
        return fullMatch;
      }

      const formattedDate = localTime.local().format('MM/DD/YY');
      const formattedTime = localTime.local().format('HH:mm');
      return `${formattedDate} | ${formattedTime}`;
    });

    return updatedContent;
  } catch (error) {
    console.error('An error occurred while formatting content:', error);
    return content;
  }
};


export const getMobileFormatedDateAndTime = (dateTime) =>{
  const date = moment(dateTime).format('MM/DD/YYYY');
  const time = moment(dateTime).format('HH:mm');
  return `${time} | ${date}`;
}



export const getFilteredNotes = (notes, currentPage, notesPerPage) => {
  const startIndex = (currentPage - 1) * notesPerPage;
  const endIndex = startIndex + notesPerPage;
  return notes.slice(startIndex, endIndex);
}

export const professionOptions = [
  "Emergency Medicine",
  "Primary Care Provider",
  // "Resident",
  // "EMS",
  // "Gastroenterologist",
  "Psychiatrist",
  "Hospitalist",
].map((p) => ({ label: p, value: p }));

export const otherOptions = [
  // "Student",
  // "Infectious Disease Specialist",
  // "Cardiologist",
  // "Clinical Psychologist",
  // "Dermatologist",
  // "Intensivist",
  // "Nephrologist",
  // "OB-GYN",
  // "Ophthalmologist",
  // "Pediatrician",
  // "Podiatrist",
  // "Psychiatrist",
  // "Radiologist",
  // "Social Worker",
  // "Speech Therapist",
  // "Surgeon",
  'Other'  
].map((p) => ({ label: p, value: p }))

export const professionGroups = [
  {
    label: "Top 4",
    options: professionOptions,
  },
  {
    label: "Other",
    options: otherOptions,
  },
];

export const getnoteTemplatesPostingData = (templates) => {
  const data = {}

  Object.entries(templates).forEach(([key, template])=> {
    data[key] = template.options
  })
  return data;
}

export const getSelectedNoteTypeParams = {
  'Notes': '?isArchived=false&hasFollowUp=false&noteType=note&paginate=false',
  'Drafts': '',
  'Completed': '?isArchived=false&hasFollowUp=true&noteType=note&paginate=false',
  'Archived': '?isArchived=true&noteType=note&paginate=false'
}

export const getFilterSelectedNoteTypeParams = {
  'All Notes': '?isArchived=false&noteType=note',
  'Completed': '?isArchived=false&hasFollowUp=true&noteType=note',
  'Pending': '?isArchived=false&hasFollowUp=false&noteType=note&excludeStatus=NOTE_CREATED'
}

export const tourStepsCreateNote = [
  {
    element: '#createNoteHeader',
    popover: {
      title: 'create Note',
      description: 'This is your Create Note Section.',
      position: 'bottom',
      side: "left",
      align: 'start',
    },
  },
  {
    element: '#sex',
    popover: {
      title: 'Sex',
      description: 'This is where you select your sex.',
      position: 'bottom',
      side: "top",
      align: 'start'
    },
  },
  {
    element: '#age',
    popover: {
      title: 'age',
      description: 'This is where you select your age.',
      position: 'bottom',
      side: "top",
      align: 'start'
    },
  },
  {
    element: '#patientName',
    popover: {
      title: 'Patient Name',
      description: 'This is where you enter patient name.',
      position: 'bottom',
      side: "top",
      align: 'start'
    },
  },
  {
    element: '#location',
    popover: {
      title: 'Location',
      description: 'This is where you select your location.',
      position: 'bottom',
      side: "top",
      align: 'start'
    },
  },
  {
    element: '#recorder',
    popover: {
      title: 'Recorder',
      description: 'This is where you select you record your new note.',
      position: 'bottom',
      side: "top",
      align: 'start'
    },
  },
]

export const tourSteps = (isValid) => {
  const steps = [
    {
      element: '#side-bar-item1',
      popover: {
        title: 'Note Templates',
        description: 'You can select Note Templates here.',
        position: 'bottom',
        side: "top",
        align: 'end'
      },
    },
    {
      element: '#createnote',
      popover: {
        title: 'Create Note',
        description: 'You can create new notes from here.',
        position: 'bottom',
        side: "top",
        align: 'end',
      },
    },
    {
      element: '#side-bar-item0',
      popover: {
        title: 'Notes',
        description: 'All your Notes will be in this section.',
        position: 'bottom',
        side: "top",
        align: 'end'
      },
    },
    // {
    //   element: '#side-bar-item1',
    //   popover: {
    //     title: 'Note Templates',
    //     description: 'You can select Note Templates here.',
    //     position: 'bottom',
    //     side: "top",
    //     align: 'end'
    //   },
    // },
    // {
    //   element: '#side-bar-item2',
    //   popover: {
    //     title: 'Analytics',
    //     description: 'You can see the entire analytical data here.',
    //     position: 'bottom',
    //     side: "top",
    //     align: 'end'
    //   },
    // },
    {
      element: '#noteTypeFilter',
      popover: {
        title: 'Note Types',
        description: 'You can see all notes either in pending or completed status',
        position: 'bottom',
        side: "bottom",
        align: 'center'
      },
    },
    ...(!isValid ? [
      {
        element: '#noteTable0',
        popover: {
          title: 'Note',
          description: 'You can click on each note here to open note details.',
          position: 'left',
          side: "left",
          align: 'center'
        },
      },
      {
        element: '#edit',
        popover: {
          title: 'Edit Note',
          description: 'You can edit your note from here.',
          position: 'left',
        },
      },
      {
        element: '#delete',
        popover: {
          title: 'Delete Note',
          description: 'You can delete your note from here.',
          position: 'left',
        },
      },
    ] : []), 
    ...(isValid ? [
      {
        element: '#noteTable',
        popover: {
          title: 'Note Table',
          description: 'This is the table from where you can see all your notes, edit or delete them as needed.',
          position: 'left',
        },
      },
    ] : []),
    {
      element: '#pagination',
      popover: {
        title: 'Pagination',
        description: 'You can paginate between pages from here.',
        position: 'left',
      },
    },
  ];

  return steps.filter(step => step !== undefined);
}


export const tourStepsNoteDetails = (isValid) => [
  {
    element: '#backToDashboard',
    popover: {
      title: 'Back to Dashboard',
      description: 'You can go back to dashboard from here.',
      position: 'left',
      side: "left",
      align: 'center'
    },
  },
  {
    element: '#noteValue0',
    popover: {
      title: 'Care Update',
      description: 'You can record your care update from here.',
      position: 'left',
    },
  },
  {
    element: '#noteValue1',
    popover: {
      title: 'EKG',
      description: 'You can record your EKG from here.',
      position: 'left',
    },
  },
  {
    element: '#noteValue2',
    popover: {
      title: 'Disposition',
      description: 'You can record your disposition from here.',
      position: 'left',
    },
  },
  {
    element: '#copyAll',
    popover: {
      title: 'Copy All',
      description: 'You can copy entire note details from here.',
      position: 'left',
    },
  },
  {
    element: '#copySelected',
    popover: {
      title: 'Copy Selected',
      description: 'You can copy only the selected from here.',
      position: 'left',
    },
  },
  {
    element: '#unSelect',
    popover: {
      title: 'Unselect',
      description: 'You can un-select all from here.',
      position: 'left',
    },
  },
  {
    element: '#noteDetails',
    popover: {
      title: 'Note',
      description: 'This is where your note details are being showed',
      position: 'left',
    },
  },
  {
    element: '#disposition',
    popover: {
      title: 'Disposition',
      description: isValid ? 'This is where your disposition details are being showed' : 'Your dispostion section will be shown on the right side of this note section when you add it',
      position: 'left',
    },
  },
]
export const tourStepsCustomNote = [
  {
    element: '#customNoteHead0',
    popover: {
      title: 'Note Sections',
      description: 'This is your Note Section',
      position: 'top',
    },
  },
  {
    element: '#customNotechief_complaint_s',
    popover: {
      title: 'Alter Detail',
      description: 'You can alter the order of details from here',
      position: 'top',
    },
  },
  {
    element: '#customNoteAddSection',
    popover: {
      title: 'Add Section',
      description: 'You can add your own news section from here',
      position: 'top',
    },
  },
  {
    element: '#customNoteHead1',
    popover: {
      title: 'Care update section',
      description: 'This is your care update section ',
      position: 'top',
    },
  },
  {
    element: '#customNoteHead2',
    popover: {
      title: 'Disposition Sction',
      description: 'This is your disposition section.',
      position: 'top',
    },
  },
  {
    element: '#customNotedisposition',
    popover: {
      title: 'Alter Details',
      description: 'You can alter the order of details from here',
      position: 'top',
    },
  },
  {
    element: '#customPhrases',
    popover: {
      title: 'Custom phrases',
      description: 'This is your custom Phrases section.',
      position: 'top',
    },
  },
  {
    element: '#addCustomPhrases',
    popover: {
      title: 'Add Custom Phrase',
      description: 'You can add new custom phrase from here',
      position: 'top',
    },
  },
  // {
  //   element: '#addEditCustomPhrases',
  //   popover: {
  //     title: 'Delete Note',
  //     description: 'You can delete your note from here.',
  //     position: 'top',
  //   },
  // },
  {
    element: '#update',
    popover: {
      title: 'Update Section',
      description: 'This is where your add/edit custom phrase form is. The custom phrases created will be shown in the left section.',
      position: 'top',
    },
  },
]

export const statasesvalues = {
  NOTE_CREATED: "Processing Note...",
    NEW_UPLOAD_STARTED: "Processing Note...",
    NEW_UPLOADED: "Processing Note...",
    FOLLOW_UP_UPLOAD_STARTED: "Processing Disposition Note...",
    FOLLOW_UP_UPLOADED: "Processing Disposition Note...",
    SUMMARIZING_ORIGINAL: "Summarizing Original Note...",
    TRANSCRIBING_ORIGINAL: "Transcribing Original Note...",
    TRANSCRIBING_FOLLOWUP: "Transcribing Disposition Note...",
    
    CARE_UP_UPLOAD_STARTED: "Processing Care-Update Note...",
    CARE_UP_UPLOADED: "Processing Care-Update Note...",
    SUMMARIZING_CARE_UP: "Summarizing Care-Update Note...",
    TRANSCRIBING_CARE_UP: "Transcribing Care-Update Note...",
    
    EKG_UPLOAD_STARTED: "Processing EKG Note...",
    EKG_UPLOADED: "Processing EKG Note...",
    TRANSCRIBING_EKG: "Transcribing EKG Note...",
    SUMMARIZING_FOLLOWUP: "Summarizing Disposition Note...",
    SUMMARIZING_EKG: "Summarizing EKG Note...",
}

export const createNoteInitialState = {
  innitials: '',
  age: '',
  ageType: 'YEARS',
  sex: '',
  location: '',
  locationType: ''
}


export const numericsLocations = ['ROOM', 'CHAIR'];


export const getNoteStatusInfo = (status) => {
  if(status === 'ERROR')
    return { text: status, color: 'error'}
  if(status === 'NOTE_CREATED')
    return { text: 'INITIALIZED', color: 'default'}
  return {text: 'PENDING', color: 'warning'}
}

export const percSectionData = {
  name: "PERC Rule for Pulmonary Embolism",
  sections: [
    {
      name: "Age ≥50",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "HR ≥100",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "O₂ sat on room air <95%",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Unilateral leg swelling",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Hemoptysis",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Recent surgery or trauma",
      description: "Surgery or trauma ≤4 weeks ago requiring treatment with general anesthesia",
      options: [
        {
          id: 10,
          label: "No",
          value: 0
        },
        {
          id: 11,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Prior PE or DVT",
      options: [
        {
          id: 12,
          label: "No",
          value: 0
        },
        {
          id: 13,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Hormone Use",
      description: "Oral contraceptives, hormone replacement or estrogenic hormones use in males or female patients",
      options: [
        {
          id: 14,
          label: "No",
          value: 0
        },
        {
          id: 15,
          label: "Yes",
          value: 1
        }
      ]
    }
  ]
};


export const heartSectionData = {
  "name": "Heart",
  "sections": [
    {
      "name": "History",
      "options": [
        {
          "id": 0,
          "label": "Slightly suspicious",
          "value": 0
        },
        {
          "id": 1,
          "label": "Moderately suspicious",
          "value": 1
        },
        {
          "id": 2,
          "label": "Highly suspicious",
          "value": 2
        }
      ]
    },
    {
      "name": "EKG",
      "description": "1 point: No ST deviation but LBBB, LVH, repolarization changes (e.g. digoxin); 2 points: ST deviation not due to LBBB, LVH, or digoxin",
      "options": [
        {
          "id": 0,
          "label": "Normal",
          "value": 0
        },
        {
          "id": 1,
          "label": "Non-specific repolarization disturbance",
          "value": 1
        },
        {
          "id": 2,
          "label": "Significant ST deviation",
          "value": 2
        }
      ]
    },
    {
      "name": "Age",
      "options": [
        {
          "id": 0,
          "label": "<45",
          "value": 0
        },
        {
          "id": 1,
          "label": "45-64",
          "value": 1
        },
        {
          "id": 2,
          "label": "≥65",
          "value": 2
        }
      ]
    },
    {
      "name": "Risk Factors",
      "description": "Risk factors: HTN, hypercholesterolemia, DM, obesity (BMI >30 kg/m²), smoking (current, or smoking cessation ≤3 mo), positive family history (parent or sibling with CVD before age 65); atherosclerotic disease: prior MI, PCI/CABG, CVA/TIA, or peripheral arterial disease",
      "options": [
        {
          "id": 0,
          "label": "No known risk factors",
          "value": 0
        },
        {
          "id": 1,
          "label": "1-2 risk factors",
          "value": 1
        },
        {
          "id": 2,
          "label": "≥3 risk factors or history of atherosclerotic disease",
          "value": 2
        }
      ]
    },
    {
      "name": "Initial Troponin",
      "description": "Use local, regular sensitivity troponin assays and corresponding cutoffs",
      "options": [
        {
          "id": 0,
          "label": "≤normal limit",
          "value": 0
        },
        {
          "id": 1,
          "label": "1–3× normal limit",
          "value": 1
        },
        {
          "id": 2,
          "label": ">3× normal limit",
          "value": 2
        }
      ]
    }
  ]
}


const nihssData = {
  name: 'NIHSS',
  sections: [
  {
    name: "1A: Level of consciousness",
    description: "May be assessed casually while taking history",
    options: [
      { id: 0, label: "Alert; keenly responsive", value: 0 },
      { id: 1, label: "Arouses to minor stimulation", value: 1 },
      { id: 2, label: "Requires repeated stimulation to arouse", value: 2 },
      { id: 3, label: "Movements to pain", value: 2 },
      { id: 4, label: "Postures or unresponsive", value: 3 },
    ],
  },
  {
    name: "1B: Ask month and age",
    options: [
      { id: 0, label: "Both questions right", value: 0 },
      { id: 1, label: "1 question right", value: 1 },
      { id: 2, label: "0 questions right", value: 2 },
      { id: 3, label: "Dysarthric/intubated/trauma/language barrier", value: 1 },
      { id: 4, label: "Aphasic", value: 2 },
    ],
  },
  {
    name: "'Blink eyes' & 'squeeze hands'",
    description: "Pantomime commands if communication barrier",
    options: [
      { id: 0, label: "Performs both tasks", value: 0 },
      { id: 1, label: "Performs 1 task", value: 1 },
      { id: 2, label: "Performs 0 tasks", value: 2 },
    ],
  },
  {
    name: "2: Horizontal extraocular movements",
    description: "Only assess horizontal gaze",
    options: [
      { id: 0, label: "Normal", value: 0 },
      { id: 1, label: "Partial gaze palsy: can be overcome", value: 1 },
      { id: 2, label: "Partial gaze palsy: corrects with oculocephalic reflex", value: 1 },
      { id: 3, label: "Forced gaze palsy: cannot be overcome", value: 2 },
    ],
  },
  {
    name: "3: Visual fields",
    options: [
      { id: 0, label: "No visual loss", value: 0 },
      { id: 1, label: "Partial hemianopia", value: 1 },
      { id: 2, label: "Complete hemianopia", value: 2 },
      { id: 3, label: "Patient is bilaterally blind", value: 3 },
      { id: 4, label: "Bilateral hemianopia", value: 3 },
    ],
  },
  {
    name: "4: Facial palsy",
    description: "Use grimace if obtunded",
    options: [
      { id: 0, label: "Normal symmetry", value: 0 },
      { id: 1, label: "Minor paralysis (flat nasolabial fold, smile asymmetry)", value: 1 },
      { id: 2, label: "Partial paralysis (lower face)", value: 2 },
      { id: 3, label: "Unilateral complete paralysis (upper/lower face)", value: 3 },
      { id: 4, label: "Bilateral complete paralysis (upper/lower face)", value: 3 },
    ],
  },
  {
    name: "5A: Left arm motor drift",
    description: "Count out loud and use your fingers to show the patient your count",
    options: [
      { id: 0, label: "No drift for 10 seconds", value: 0 },
      { id: 1, label: "Drift, but doesn't hit bed", value: 1 },
      { id: 2, label: "Drift, hits bed", value: 2 },
      { id: 3, label: "Some effort against gravity", value: 2 },
      { id: 4, label: "No effort against gravity", value: 3 },
      { id: 5, label: "No movement", value: 4 },
      { id: 6, label: "Amputation/joint fusion", value: 0 },
    ],
  },
  {
    name: "5B: Right arm motor drift",
    description: "Count out loud and use your fingers to show the patient your count",
    options: [
      { id: 0, label: "No drift for 10 seconds", value: 0 },
      { id: 1, label: "Drift, but doesn't hit bed", value: 1 },
      { id: 2, label: "Drift, hits bed", value: 2 },
      { id: 3, label: "Some effort against gravity", value: 2 },
      { id: 4, label: "No effort against gravity", value: 3 },
      { id: 5, label: "No movement", value: 4 },
      { id: 6, label: "Amputation/joint fusion", value: 0 },
    ],
  },
  {
    name: "6A: Left leg motor drift",
    options: [
      { id: 0, label: "No drift for 5 seconds", value: 0 },
      { id: 1, label: "Drift, but doesn't hit bed", value: 1 },
      { id: 2, label: "Drift, hits bed", value: 2 },
      { id: 3, label: "Some effort against gravity", value: 2 },
      { id: 4, label: "No effort against gravity", value: 3 },
      { id: 5, label: "No movement", value: 4 },
      { id: 6, label: "Amputation/joint fusion", value: 0 },
    ],
  },
  {
    name: "6B: Right leg motor drift",
    options: [
      { id: 0, label: "No drift for 5 seconds", value: 0 },
      { id: 1, label: "Drift, but doesn't hit bed", value: 1 },
      { id: 2, label: "Drift, hits bed", value: 2 },
      { id: 3, label: "Some effort against gravity", value: 2 },
      { id: 4, label: "No effort against gravity", value: 3 },
      { id: 5, label: "No movement", value: 4 },
      { id: 6, label: "Amputation/joint fusion", value: 0 },
    ],
  },
  {
    name: "7: Limb ataxia",
    description: "Test finger-nose, heel-shin",
    options: [
      { id: 0, label: "No ataxia", value: 0 },
      { id: 1, label: "Ataxia in 1 limb", value: 1 },
      { id: 2, label: "Ataxia in 2 limbs", value: 2 },
      { id: 3, label: "Paralyzed, no test", value: 0 },
    ],
  },
  {
    name: "8: Sensation",
    options: [
      { id: 0, label: "Normal; no sensory loss", value: 0 },
      { id: 1, label: "Mild sensory loss", value: 1 },
      { id: 2, label: "Complete hemianesthesia", value: 2 },
    ],
  },
  {
    name: "9: Language/aphasia",
    options: [
      { id: 0, label: "Normal", value: 0 },
      { id: 1, label: "Mild-moderate aphasia", value: 1 },
      { id: 2, label: "Severe aphasia", value: 2 },
      { id: 3, label: "Mute", value: 3 },
      { id: 4, label: "Intubated, trauma/language barrier", value: 1 },
    ],
  },
  {
    name: "10: Dysarthria",
    options: [
      { id: 0, label: "Normal", value: 0 },
      { id: 1, label: "Mild-moderate dysarthria", value: 1 },
      { id: 2, label: "Severe dysarthria", value: 2 },
      { id: 3, label: "Intubated or mechanical barriers", value: 1 },
    ],
  },
  {
    name: "11: Extinction/inattention",
    options: [
      { id: 0, label: "Normal", value: 0 },
      { id: 1, label: "Neglect in 1 modality", value: 1 },
      { id: 2, label: "Neglect in 2 modalities", value: 2 },
    ],
  },
]
};


export const pecarn = {
  'PECARN Pediatric Head Injury/Trauma Algorithm' : {
    name: "Age",
    options: [
      {
        id: 0,
        label: "<2 Years",
        section: {
          name: "GCS ≤14, palpable skull fracture or signs of AMS",
          description:
            "AMS: Agitation, somnolence, repetitive questioning, or slow response to verbal communication",
          options: [
            {
              id: 0,
              label: "No",
              section: {
                name: "Occipital, parietal or temporal scalp hematoma; history of LOC ≥5 sec; not acting normally per parent or severe mechanism of injury?",
                description:
                  "Severe mechanism: MVC with patient ejection, death of another passenger, rollover; pedestrian or bicyclist w/o helmet struck by motorized vehicle; fall from >0.9m or 3ft; head struck by high-impact object",
                options: [
                  {
                    id: 0,
                    label: "No",
                    result:
                      "PECARN recommends No CT; Risk of ciTBI <0.02%, “Exceedingly Low, generally lower than risk of CT-induced malignancies.",
                  },
                  {
                    id: 1,
                    label: "Yes",
                    result:
                      "PECARN recommends observation over imaging, depending on provider comfort; 0.9% risk of clinically important Traumatic Brain Injury.\n\nConsider the following when making imaging decisions: Physician experience, worsening signs/symptoms during observation period, age <3 months, parent preference, multiple vs. isolated findings: patients with certain isolated findings (i.e., no other findings suggestive of TBI), such as isolated LOC, isolated headache, isolated vomiting, and certain types of isolated scalp hematomas in infants >3 months have ciTBI risk substantially <1%.",
                  },
                ],
              },
            },
            {
              id: 1,
              label: "Yes",
              result:
                "PECARN recommends CT; 4.4% risk of clinically important Traumatic Brain Injury.",
            },
          ],
        },
      },
      {
        id: 1,
        label: "≥2 Years",
        section: {
          name: "GCS ≤14 or signs of basilar skull fracture or signs of AMS",
          description:
            "AMS: Agitation, somnolence, repetitive questioning, or slow response to verbal communication",
          options: [
            {
              id: 0,
              label: "No",
              section: {
                name: "History of LOC or history of vomiting or severe headache or severe mechanism of injury",
                description:
                  "Motor vehicle crash with patient ejection, death of another passenger, or rollover; pedestrian or bicyclist without helmet struck by a motorized vehicle; falls of more than 1.5m/5ft; head struck by a high-impact object",
                options: [
                  {
                    id: 0,
                    label: "No",
                    result:
                      "PECARN recommends No CT; Risk <0.05%, “Exceedingly Low, generally lower than risk of CT-induced malignancies.",
                  },
                  {
                    id: 1,
                    label: "Yes",
                    result:
                      "PECARN recommends observation over imaging, depending on provider comfort; 0.9% risk of clinically important Traumatic Brain Injury.\n\nConsider the following when making imaging decisions: Physician experience, worsening signs/symptoms during observation period, age <3 months, parent preference, multiple vs. isolated findings: patients with certain isolated findings (i.e., no other findings suggestive of TBI), such as isolated LOC, isolated headache, isolated vomiting, and certain types of isolated scalp hematomas in infants >3 months have ciTBI risk substantially <1%.",
                  },
                ],
              },
            },
            {
              id: 1,
              label: "Yes",
              result:
                "PECARN recommends CT; 4.3% risk of clinically important Traumatic Brain Injury.",
            },
          ],
        },
      },
    ],
  },
};


export const wellsCriteriaDVT = {
  name: "Wells' Criteria for DVT",
  sections: [
    {
      name: "Active cancer",
      description: "Treatment or palliation within 6 months",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Bedridden recently",
      description: "Bedridden recently >3 days or major surgery within 12 weeks",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Calf swelling >3 cm compared to the other leg",
      description: "Measured 10 cm below tibial tuberosity",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Collateral (nonvaricose) superficial veins present",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Entire leg swollen",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Localized tenderness along the deep venous system",
      options: [
        {
          id: 10,
          label: "No",
          value: 0
        },
        {
          id: 11,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Pitting edema, confined to symptomatic leg",
      options: [
        {
          id: 12,
          label: "No",
          value: 0
        },
        {
          id: 13,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Paralysis, paresis, or recent plaster immobilization of the lower extremity",
      options: [
        {
          id: 14,
          label: "No",
          value: 0
        },
        {
          id: 15,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Previously documented DVT",
      options: [
        {
          id: 16,
          label: "No",
          value: 0
        },
        {
          id: 17,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Alternative diagnosis to DVT as likely or more likely",
      options: [
        {
          id: 18,
          label: "No",
          value: 0
        },
        {
          id: 19,
          label: "Yes",
          value: -2
        }
      ]
    }
  ]
};

export const spinalAssessment = {
  name: "NEXUS Criteria for C-Spine Imaging",
  sections: [
    {
      name: "Focal neurologic deficit present",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Midline spinal tenderness present",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Altered level of consciousness present",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Intoxication present",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Distracting injury present",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1
        }
      ]
    }
  ]
};

export const wellsCriteriaPE = {
  name: "Wells' Criteria for Pulmonary Embolism",
  subDetails: "Low Risk = 1.3% chance of PE in an ED population\nModerate Risk 16.2% chance of PE in an ED population\nHigh Risk 40.6% chance of PE in an ED population.\nUnlikely PE: 3% incidence of PE.\nLikely PE: 28% incidence of PE.",
  sections: [
    {
      name: "Clinical signs and symptoms of DVT",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 3
        }
      ]
    },
    {
      name: "PE is #1 diagnosis OR equally likely",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 3
        }
      ]
    },
    {
      name: "Heart rate > 100",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1.5
        }
      ]
    },
    {
      name: "Immobilization at least 3 days OR surgery in the previous 4 weeks",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1.5
        }
      ]
    },
    {
      name: "Previous, objectively diagnosed PE or DVT",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1.5
        }
      ]
    },
    {
      name: "Hemoptysis",
      options: [
        {
          id: 10,
          label: "No",
          value: 0
        },
        {
          id: 11,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Malignancy w/ treatment within 6 months or palliative",
      options: [
        {
          id: 12,
          label: "No",
          value: 0
        },
        {
          id: 13,
          label: "Yes",
          value: 1
        }
      ]
    }
  ]
};

export const curb65PneumoniaSeverity = {
  name: "CURB-65 Score for Pneumonia Severity",
  subDetails: "1: Low risk: with a mortality risk of less than 3%\n2: Intermediate risk, with a mortality risk of 3–15%\n3 to 5: High risk, with a mortality risk of more than 15%",
  sections: [
    {
      name: "Confusion",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "BUN >19 mg/dL (>7 mmol/L urea)",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Respiratory Rate ≥ 30",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Systolic BP <90 mmHg or Diastolic BP ≤60 mmHg",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Age ≥ 65",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1
        }
      ]
    }
  ]
};

export const updatedPsiPortScore = {
  name: "PSI/PORT Score",
  sections: [
    {
      name: "Sex",
      options: [
        {
          id: 0,
          label: "Female",
          value: -10,
          disabled: true,
        },
        {
          id: 1,
          label: "Male",
          value: 0,
          disabled: true,
        }
      ]
    },
    {
      name: "Nursing home resident",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Neoplastic disease",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 30
        }
      ]
    },
    {
      name: "Liver disease history",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "CHF history",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Cerebrovascular disease history",
      options: [
        {
          id: 10,
          label: "No",
          value: 0
        },
        {
          id: 11,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Renal disease history",
      options: [
        {
          id: 12,
          label: "No",
          value: 0
        },
        {
          id: 13,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Altered mental status",
      options: [
        {
          id: 14,
          label: "No",
          value: 0
        },
        {
          id: 15,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "Respiratory rate ≥ 30 breaths/min",
      options: [
        {
          id: 16,
          label: "No",
          value: 0
        },
        {
          id: 17,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "Systolic blood pressure <90 mmHg",
      options: [
        {
          id: 18,
          label: "No",
          value: 0
        },
        {
          id: 19,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "Temperature <35°C (95°F) or ≥ 40°C (104°F)",
      options: [
        {
          id: 20,
          label: "No",
          value: 0
        },
        {
          id: 21,
          label: "Yes",
          value: 15
        }
      ]
    },
    {
      name: "Pulse ≥ 125 beats/min",
      options: [
        {
          id: 22,
          label: "No",
          value: 0
        },
        {
          id: 23,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "pH < 7.35",
      options: [
        {
          id: 24,
          label: "No",
          value: 0
        },
        {
          id: 25,
          label: "Yes",
          value: 30
        }
      ]
    },
    {
      name: "BUN > 30 mg/dL or ≥ 11 mmol/L",
      options: [
        {
          id: 26,
          label: "No",
          value: 0
        },
        {
          id: 27,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "Sodium < 130 mmol/L",
      options: [
        {
          id: 28,
          label: "No",
          value: 0
        },
        {
          id: 29,
          label: "Yes",
          value: 20
        }
      ]
    },
    {
      name: "Glucose ≥ 250 mg/dL or > 14 mmol/L",
      options: [
        {
          id: 30,
          label: "No",
          value: 0
        },
        {
          id: 31,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Hematocrit < 30%",
      options: [
        {
          id: 32,
          label: "No",
          value: 0
        },
        {
          id: 33,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Partial pressure of oxygen < 60 mmHg or < 8 kPa",
      options: [
        {
          id: 34,
          label: "No",
          value: 0
        },
        {
          id: 35,
          label: "Yes",
          value: 10
        }
      ]
    },
    {
      name: "Pleural effusion on x-ray",
      options: [
        {
          id: 36,
          label: "No",
          value: 0
        },
        {
          id: 37,
          label: "Yes",
          value: 10
        }
      ]
    }
  ]
};

export const canadianCTHeadScreening =  [
  {
    name: "Age <16 years",
    options: [
      {
        id: 0,
        label: "No",
        value: 0
      },
      {
        id: 1,
        label: "Yes",
        value: 1
      }
    ]
  },
  {
    name: "Patient on blood thinners",
    options: [
      {
        id: 2,
        label: "No",
        value: 0
      },
      {
        id: 3,
        label: "Yes",
        value: 1
      }
    ]
  },
  {
    name: "Seizure after injury",
    options: [
      {
        id: 4,
        label: "No",
        value: 0
      },
      {
        id: 5,
        label: "Yes",
        value: 1
      }
    ]
  }
]

export const canadianCTHeadRule2 = {
  name: "Canadian CT Head Injury/Trauma Rule",
  sections: [
    {
      name: "Initial Screening Questions",
      items: [
        {
          name: "Age <16 years",
          options: [
            {
              id: 0,
              label: "No",
              value: null
            },
            {
              id: 1,
              label: "Yes",
              value: null
            }
          ]
        },
        {
          name: "Patient on blood thinners",
          options: [
            {
              id: 2,
              label: "No",
              value: null
            },
            {
              id: 3,
              label: "Yes",
              value: null
            }
          ]
        },
        {
          name: "Seizure after injury",
          options: [
            {
              id: 4,
              label: "No",
              value: null
            },
            {
              id: 5,
              label: "Yes",
              value: null
            }
          ]
        }
      ]
    },
    {
      name: "High Risk Criteria: Rules out need for neurosurgical intervention",
      items: [
        {
          name: "GCS <15 at 2 hours post-injury",
          options: [
            {
              id: 6,
              label: "No",
              value: 0
            },
            {
              id: 7,
              label: "Yes",
              value: 1
            }
          ]
        },
        {
          name: "Suspected open or depressed skull fracture",
          options: [
            {
              id: 8,
              label: "No",
              value: 0
            },
            {
              id: 9,
              label: "Yes",
              value: 1
            }
          ]
        },
        {
          name: "Any sign of basilar skull fracture? Hemotympanum, raccoon eyes, Battle's Sign, CSF oto-/rhinorrhea",
          options: [
            {
              id: 10,
              label: "No",
              value: 0
            },
            {
              id: 11,
              label: "Yes",
              value: 1
            }
          ]
        },
        {
          name: "≥2 episodes of vomiting",
          options: [
            {
              id: 12,
              label: "No",
              value: 0
            },
            {
              id: 13,
              label: "Yes",
              value: 1
            }
          ]
        },
        {
          name: "Age ≥65 years",
          options: [
            {
              id: 14,
              label: "No",
              value: 0
            },
            {
              id: 15,
              label: "Yes",
              value: 1
            }
          ]
        }
      ]
    },
    {
      name: "Medium Risk Criteria: In addition to above, rules out \"clinically important\" brain injury (positive CT's that normally require admission)",
      items: [
        {
          name: "Retrograde amnesia to the event ≥ 30 minutes",
          options: [
            {
              id: 16,
              label: "No",
              value: 0
            },
            {
              id: 17,
              label: "Yes",
              value: 1
            }
          ]
        },
        {
          name: "\"Dangerous\" mechanism? Pedestrian struck by motor vehicle, occupant ejected from motor vehicle, or fall from >3 feet or >5 stairs",
          options: [
            {
              id: 18,
              label: "No",
              value: 0
            },
            {
              id: 19,
              label: "Yes",
              value: 1
            }
          ]
        }
      ]
    }
  ]
};

export const canadianCTHeadRule = {
  name: "Canadian CT Head Injury/Trauma Rule",
  sections: [
    {
      name: "GCS <15 at 2 hours post-injury",
      options: [
        {
          id: 0,
          label: "No",
          value: 0
        },
        {
          id: 1,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Suspected open or depressed skull fracture",
      options: [
        {
          id: 2,
          label: "No",
          value: 0
        },
        {
          id: 3,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Any sign of basilar skull fracture? Hemotympanum",
      description: "Raccoon eyes, Battle's Sign, CSF oto-/rhinorrhea",
      options: [
        {
          id: 4,
          label: "No",
          value: 0
        },
        {
          id: 5,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "≥2 episodes of vomiting",
      options: [
        {
          id: 6,
          label: "No",
          value: 0
        },
        {
          id: 7,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Age ≥65 years",
      options: [
        {
          id: 8,
          label: "No",
          value: 0
        },
        {
          id: 9,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "Retrograde amnesia to the event ≥ 30 minutes",
      options: [
        {
          id: 10,
          label: "No",
          value: 0
        },
        {
          id: 11,
          label: "Yes",
          value: 1
        }
      ]
    },
    {
      name: "“Dangerous” mechanism?",
      description: 'Pedestrian struck by motor vehicle, occupant ejected from motor vehicle, or fall from >3 feet or >5 stairs',
      options: [
        {
          id: 12,
          label: "No",
          value: 0
        },
        {
          id: 13,
          label: "Yes",
          value: 1
        }
      ]
    }
  ]
};



export const clinicalDecesionTools = [
  {
    tool: 'MIPS',
    componenet:()=> <Mips />
  },
  {
    tool: 'HEART',
    componenet: ()=> <Heart data={heartSectionData} evaluateDataOnPoints={heartEvaluation} />
  },
  {
    tool: 'NIHSS',
    componenet: ()=> <Heart data={nihssData} />
  },
  {
    tool: 'PECARN',
    componenet: ()=> <Pecarn data={pecarn["PECARN Pediatric Head Injury/Trauma Algorithm"]} />
  },
  {
    tool: 'PERC',
    componenet:  ()=> <Heart data={percSectionData} evaluateDataOnPoints={percEvaluation} />
  },
  {
    tool: 'WELL DVT',
    componenet:  ()=><Heart data={wellsCriteriaDVT} evaluateDataOnPoints={dvtEvaluation} />
  },
  {
    tool: 'NEXUS spine',
    componenet:  ()=> <Heart data={spinalAssessment} evaluateDataOnPoints={nexusEvaluation}/>
  },
  {
    tool: 'WELL PE',
    componenet:  ()=> <Heart data={wellsCriteriaPE} evaluateDataOnPoints={wellpeEvaluation} />
  },
  {
    tool: 'CURB-65 score',
    componenet:  ()=> <Heart data={curb65PneumoniaSeverity} evaluateDataOnPoints={curbEvaluation}/>
  },
  {
    tool: 'PSE/PORT score',
    componenet:  ({age, ageType, sex})=> {
      const Sex = sex === 'MALE' ? {id: 1, label: 'Male', value: 0} : {id: 0, label: 'Female', value: -10}
      const preSelected = [{name: 'Age', value: `${age} ${ageType}`}]
      return <Heart data={updatedPsiPortScore} 
      preSelected={preSelected} 
      evaluateDataOnPoints={portEvaluation}
      defaultSelections={{
        Sex,
      }} />
    }
  },
  {
    tool: 'CT head',
    componenet : ()=> <CTHead evaluateDataOnPoints={ctEvaluation}/>
  }
  // {
  //   tool: 'VTE',
  //   componenet: ({age, ageType}) => {
  //     return <VTE />
  //   }
  // }
]

export const subscriptionBenefits = [
  'Unlimited doctor-patient notes',
  'Access to transcriptions and medical summaries',
  'Editable History of Present Illness',
  'Advanced content copy functionality of medical information',
  'Mobile app access and synchronization of patient transcriptions',
  'Email and priority phone support',
  'Access to medical decision making results',
];


export const temoNoteDetailsContent = {
  "chief_complaint": {
    "content": "Dog bite to the left upper extremity",
    "key": "chief_complaint",
    "heading": "Chief Complaint (S)"
  },
  "history_of_present_illness": {
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultricies mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales.",
    "key": "history_of_present_illness",
    "heading": "History of present illness (HPI)"
  },
  "review_of_systems": {
    "content": "Left upper extremity: Punctured wounds, hemostatic with pressure",
    "key": "review_of_systems",
    "heading": "Review of systems"
  },
  "physical_exam": {
    "content": "Left upper extremity: Punctured wounds, hemostatic with pressure",
    "key": "physical_exam", 
    "heading": "Physical Exam"
  },
  "assessment": {
    "content": "Left upper extremity: Punctured wounds, hemostatic with pressure",
    "key": "assessment", 
    "heading": "Assessment"
  },
  "ekg": {
    "content": "14:48\nNormal Sinus Rhythm\nVentricular Rate: 44 BPM\nPR Interval: 120",
    "key": "ekg",
    "createdAt": "14:48", 
    "heading": "EKG"
  },
  "reassessment": {
    "content": "Left upper extremity: Punctured wounds, hemostatic with pressure",
    "key": "reassessment", 
    "heading": "Reassessment"
  }
}


export const getCopyContentFromValue = (value) => {
  let contentToCopy = '';

  if (typeof value === 'object' && value !== null) {
    if (value.key === 'ekg' || value.key === 'care-update') {
      if (Array.isArray(value.content) && value.content.length > 0) {
        const subHeading = value.content[0].heading;
        contentToCopy += `${subHeading}\n`;

        value.content.forEach((item) => {
          contentToCopy += `${convertUTCDatetoLocal(item?.createdAt)}\n${item.content}\n\n`;
        });
      }
    } else {
      contentToCopy += `${value.heading}\n`;
      contentToCopy += value?.createdAt ? `${convertUTCDatetoLocal(value?.createdAt)}\n` : '';
      contentToCopy += `${value.content}\n`;
    }
  }

  return contentToCopy;
};


export  function convertUTCDatetoLocal (utcDateTime) {
  const [date, time] = utcDateTime.split('|').map(part => part.trim());

    const localDateTime = moment.utc(`${date} ${time}`, "MM/DD/YY HH:mm").local().format("MM/DD/YY | HH:mm");

    return localDateTime;
}

export const convertUTCtoLocal = (content) => {
  const dateTimeRegex = /\b\d{2}\/\d{2}\/\d{2}\s*\|\s*\d{2}:\d{2}\b/g;

  const newContent = content.replace(dateTimeRegex, (utcDateTime) => {

    const [date, time] = utcDateTime.split('|').map(part => part.trim());

    const localDateTime = moment.utc(`${date} ${time}`, "MM/DD/YY HH:mm").local().format("MM/DD/YY | HH:mm");

    return localDateTime;
  });

  return newContent;
};

export const contentBody = encodeURIComponent(`Hey! I’ve been using Time by Dr. Haydar, and it’s been a game-changer for managing health and wellness. You can check out the dashboard here: https://time.drh.ai , or download the app to get started!\n\nAndroid: https://play.google.com/store/apps/details?id=com.drhaydar.time&hl=en&pli=1 \n\niOS: https://apps.apple.com/us/app/time-by-dr-haydar/id6464188878 \n\nLet me know what you think!`);


export const scoreRanges = {
  'heart': [
    {
      min: 0,
      max: 3,
      description: "Low Score (0-3 points)\n\nRisk of MACE of 0.9-1.7%.",
    },
    {
      min: 4,
      max: 6,
      description:
        "Moderate Score (4-6 points)\n\nRisk of MACE of 12-16.6%.\n\nIf EKG is highly suspicious, many experts recommend further workup and admission even with a low HEART Score.",
    },
    {
      min: 7,
      description: "High Score (7-10 points)\n\nRisk of MACE of 50-65%.",
    },
  ],
};


function heartEvaluation (points = -1){
  if(points < 0)
    return '';
  else if(points >= 0 && points <=3)
    return "Low Score (0-3 points)\n\nRisk of MACE of 0.9-1.7%."
  else if(points >= 4 && points <=6)
    return "Moderate Score (4-6 points)\n\nRisk of MACE of 12-16.6%.\n\nIf EKG is highly suspicious, many experts recommend further workup and admission even with a low HEART Score."
  else 
    return "High Score (7-10 points)\n\nRisk of MACE of 50-65%.";
}

function percEvaluation (points = -1){
  if(points <= 0)
    return 'No need for further workup, as <2% chance of pulmonary embolism(PE). If no criteria are positive and your pre-test probability is <15%, PERC rule criteria are satisfied.';
  else 
    return "You have a positive criteria, the PERC rule cannot be used to rule out PE";
}

function ctEvaluation (points = -1){
  if(points <= 0)
    return 'CT Head is not necessary for this patient (sensitivity 83-100% for all intracranial traumatic findings, sensitivity 100% for findings requiring neurosurgical intervention)';
  else 
    return "Consider CT";
}
function nexusEvaluation (points = -1){
  if(points <= 0)
    return 'C-Spine imaging not required';
  else 
    return "Consider Imaging. C-Spine cannot be cleared clinically by these criterias";
}
function wellpeEvaluation (points = -1){
  if(points >= 0 && points <= 1.5)
    return `Low Risk for PE & PE Unlikely`
else if(points >= 2 && points <=6)
  return `Moderate Risk for PE & PE Unlikely`
else
  return `High Risk for PE & PE Likely`;
}

function curbEvaluation (points = -1){
  if(points == 0)
    return `Low risk group: Consider outpatient treatment. 0.6% 30-day mortality.`
else if(points == 1)
  return `Low risk group: Consider outpatient treatment. 2.7% 30-day mortality.`
else if(points == 2)
  return `Moderate risk group: Consider inpatient treatment or outpatient with close followup:   6.8% 30-day mortality.`
else if(points == 3)
  return `Severe risk group: Consider inpatient treatment with possible intensive care admission. 14.0% 30-day mortality.`
else
  return `Highest risk group: Consider inpatient treatment with possible intensive care admission. 27.8% 30-day mortality.`;
}

function portEvaluation (points = -1){
  if(points < 70)
    return `Outpatient care recommended for community-acquired pneumonia`
else if(points > 70 && points <= 90)
  return `Outpatient care or observation community-acquired pneumonia`
else if(points > 90 && points <= 130)
  return `Inpatient care`
else
  return `ICU therapy`;
}

function dvtEvaluation(points = -1){
  if(points <= 0)
    return ` DVT unlikely. Recommend d-dimer`
else if(points === 1 || points === 2)
  return `Recommend High sensitivity d-dimer`
else
  return `Recommend Ultrasound`;
}