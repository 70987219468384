import React, { useState } from "react";
import { Box, Typography, Button, TextField, Paper, CircularProgress, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/system";
import { useUser } from "@clerk/clerk-react";
import useApiRequest from "../../hooks/useHandleRequests";

const StyledContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",
});

const StyledPaper = styled(Paper)({
  width: "100%",
  margin: "20px 0px 10px 0px",
  backgroundColor: "white",
});

const StyledHeader = styled(Box)({
  backgroundColor: "#007bff",
  color: "white",
  padding: "12px",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  backgroundColor: "white",
  height: "100%",
  "& textarea": {
    minHeight: "250px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
});

const StyledSubmitButton = styled(Button)({
  width: "100%",
  marginTop: "15px",
  backgroundColor: "#007bff",
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
  "&:disabled": {
    backgroundColor: "#ccc",
    color: "#666",
    cursor: "not-allowed",
  },
});

const RequestTemplatePage = () => {
  const { user } = useUser();
  const [note, setNote] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const { apiRequest, loading } = useApiRequest({
    handleResponse: (data) => {
      setNote("");
      setSnackbarMessage("Template request sent successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    },
    handleError: (err) => {
      console.error("API Error:", err);
      setSnackbarMessage(err?.message || "Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    },
  });

  const handleSubmit = async () => {
    if (!note.trim()) {
        setSnackbarMessage("Note cannot be empty.");
        setSnackbarSeverity("warning");
        setOpenSnackbar(true);
        return;
      }
    apiRequest("/v2/prompt/request-template", "post", {
      email: user?.emailAddresses[0]?.emailAddress,
      templateText: note,
    });
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: 2, fontSize: "32px", fontWeight: 700 }}>
        Request Template
      </Typography>
      <StyledContainer>
        <StyledPaper>
          <StyledHeader>📄 Note Template</StyledHeader>
          <StyledTextArea
            variant="outlined"
            multiline
            rows={3}
            placeholder="Type your note template here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </StyledPaper>
        <StyledSubmitButton onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Submit"}
        </StyledSubmitButton>

          <Typography variant="body2" sx={{ marginTop: 2, color: "#6c757d", textAlign: "start" }}>
            *We'll reach out to you if your template gets approved.
          </Typography>
      </StyledContainer>

      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestTemplatePage;