import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import useApiRequest from "../../hooks/useHandleRequests";

const PromptCustomization = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [selectedPromptTitle, setSelectedPromptTitle] = useState("");
  const [promptBody, setPromptBody] = useState("");

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      setIsLoading(false);
      if (data.prompts) {
        setPrompts(data.prompts); 
      } else if (data.prompt) {
        setPromptBody(data.prompt.prompt_body); 
      }
    },
    handleError: (err) => {
      console.error("Error fetching prompts:", err);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    apiRequest("/v2/prompt/all-prompts", "get");
  }, []);

  const handlePromptSelection = async (title) => {
    setSelectedPromptTitle(title);
    setPromptBody(""); 
    setIsLoading(true);
    try {
      await apiRequest(`/v2/prompt/title/${title}`, "get");
    } catch (error) {
      console.error("Error fetching prompt by title:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePrompt = async () => {
    if (!selectedPromptTitle || !promptBody.trim()) {
      alert("Please select a prompt and enter a body.");
      return;
    }

    setIsLoading(true);
    try {
      await apiRequest(`/v2/prompt/title/${selectedPromptTitle}`, "put", {
        prompt_body: promptBody,
      });
      console.log("Prompt updated successfully.");
    } catch (error) {
      console.error("Error updating prompt:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto", mt: 4, px: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            textAlign: "left",
            fontSize: "32px",
            color: "#1A202C",
            mb: 3,
          }}
        >
          Prompt Customization
        </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, color: "#202224" }}>
        Select a Prompt
      </Typography>
      <Select
        value={selectedPromptTitle}
        onChange={(e) => handlePromptSelection(e.target.value)}
        fullWidth
        displayEmpty
        sx={{
          backgroundColor: "#F3F4F6",
          borderRadius: "8px",
          mb: 1,
          "& .MuiSelect-select": { padding: "8px" },
        }}
      >
        <MenuItem value="" disabled>
          Choose a prompt...
        </MenuItem>
        {prompts.map((prompt) => (
          <MenuItem key={prompt.id} value={prompt.prompt_title}>
            {prompt.prompt_title}
          </MenuItem>
        ))}
      </Select>

      {selectedPromptTitle  && (
        <Box
          sx={{
            border: "1px solid #D1D5DB",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            p: 2,
            mt: 2,
          }}
        >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, color: "#202224" }}>
            Edit Prompt Body
          </Typography>
          {selectedPromptTitle && (
            <Button
                variant="contained"
                color="primary"
                onClick={handleUpdatePrompt}
                disabled={isLoading}
                sx={{
                backgroundColor: "#007BFF",
                color: "#FFFFFF",
                fontWeight: "bold",
                padding: "8px 12px",
                mb: 2,
                borderRadius: "8px",
                "&:hover": { backgroundColor: "#0056b3" },
                }}
            >
                {isLoading ? <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> : "Update Prompt"}
            </Button>
        )}
          </Box>
          <TextField
            fullWidth
            multiline
            rows={24}
            value={promptBody}
            onChange={(e) => setPromptBody(e.target.value)}
            variant="outlined"
            sx={{ backgroundColor: "#FAFAFA" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default PromptCustomization;
